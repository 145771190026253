import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { useState } from 'react'
import config from "../config";

import logo from "../assets/images/logo.png"
import image from "../assets/images/14.png"
import { Link, useNavigate } from 'react-router-dom'
import axios from "axios";
import auth from '../services/auth';


function SignUp() {

    const Navigate = useNavigate();

    if (auth.isLogin()) {
        Navigate('/onboarding');
    }
    const [getInputs, setUser] = useState({
        name: "", email: "", password: ""

    });
    const [loading, setLoading] = useState(false);

    let name, value;
    const handleinputs = (e) => {
        name = e.target.name;
        value = e.target.value;

        setUser({ ...getInputs, [name]: value });
    }

    const UserLogin = async (e) => {
        e.preventDefault();
        const { name, email, password } = getInputs;
        if (!name) {
            toast("please fill all information carefully")
            return
        }
        setLoading(true);
        axios.post(`${config.api_url}auth/register/`, {
            name,
            email,
            password,
        })
            .then(function (response) {
                setLoading(false);
                auth.loginUser(response.data);
                toast(response.data.message)
                Navigate("/onboarding")
            })
            .catch(function (error) {
                setLoading(false);
                toast(error.response.data.message || 'Something went wrong.Try again.')
            });

    }
    const logbtn = () => {
        Navigate("/Login")

    }

    return (
        <>
            <ToastContainer />
            <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed full-height" style={{ backgroundImage: `url(${image})` }}>
                <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                    <Link to="/register" className="mb-12">
                        <img alt="Logo" src={logo} />
                    </Link>
                    <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                        <form method='post' className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                            <div className="text-center mb-10">
                                <h1 className="text-dark mb-3">Sign Up</h1>
                            </div>
                            <div className="fv-row mb-10 fv-plugins-icon-container">
                                <label className="form-label fs-6 fw-bolder text-dark required">Name</label>
                                <input className="form-control form-control-lg form-control-solid" type="text" name="name"
                                    value={getInputs.name}
                                    onChange={handleinputs}
                                    required
                                />
                            </div>
                            <div className="fv-row mb-10 fv-plugins-icon-container">
                                <label className="form-label fs-6 fw-bolder text-dark required">Email</label>
                                <input className="form-control form-control-lg form-control-solid" type="text" name="email"
                                    value={getInputs.email}
                                    onChange={handleinputs}
                                    required
                                />
                            </div>
                            <div className="fv-row mb-10 fv-plugins-icon-container">
                                <div className="d-flex flex-stack mb-2">
                                    <label className="form-label fw-bolder text-dark fs-6 mb-0 required">Password</label>
                                </div>
                                <input className="form-control form-control-lg form-control-solid" type="password" name="password"
                                    value={getInputs.password}
                                    onChange={handleinputs}
                                    required
                                />
                                <div className="fv-plugins-message-container invalid-feedback"></div><div className="fv-plugins-message-container invalid-feedback"></div></div>
                            <div className="text-center">
                                <button type="submit" disabled={loading} className="btn btn-lg btn-primary w-100 mb-5" onClick={UserLogin}>
                                    {loading ? 'Please wait..' : 'SignUp'}
                                </button>
                                <button type="submit" className="btn btn-outline-primary w-100 mb-5" onClick={logbtn}>
                                    <span className="indicator-label">SignIn</span>
                                </button>
                            </div>
                            <div></div><div></div></form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUp