import React from 'react'
import { useEffect, useState } from 'react'
import config from "../config";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import auth from '../services/auth';
import Navbar from '../layout/Navbar';

function Sync() {

	const Navigate = useNavigate();

	useEffect(() => {
		const sync = async () => {
			try {
				toast.loading('Please wait..');

				const body = {
					shopify_access_token: auth.getStore().shopify_access_token,
					shopify_url: auth.getStore().shopify_url
				};
				await axios.post(`${config.api_url}shopify/sync/`, body);
				toast.dismiss();

			} catch (error) {
				toast.dismiss();
				if (error.response.status === 403) {
					auth.logout();
					Navigate("/")
				}
			}
		};
		sync();

	}, []);
	return (
		<>
			<Navbar />
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover

			/>
			<div className="container-xxl container-main-sec-container">
				<div className="card">
					<div className="card-body pt-6">
						<div className="mx-auto mw-800px w-100 pt-15 pb-10 fv-plugins-bootstrap5 fv-plugins-framework" >

							<div className="w-100">
								<div className="pb-8 pb-lg-10">

									<div className="text-muted fw-semibold fs-6">
									</div>
									<div className="notice d-flex bg-light-warning border-warning border border-dashed p-6">
										<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
												<rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
												<rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
											</svg>
										</span>
										<div className="d-flex flex-stack flex-grow-1">
											<div className="fw-semibold">
												<h2 className="fw-bold text-dark">You Are Done!</h2>
												<div className="fs-6 text-gray-700">The store synch has been activated. You can relax, synced Inventory will be shown once its completed.
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>


							<div className="d-flex flex-stack pt-15">
								<Link to="/inventories" type="button" className="btn btn-lg btn-primary">

									Show Inventory
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Sync