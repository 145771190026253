import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import "./components/assets/css/style.bundle.css"
import Synchistory from './components/pages/Synchistory'
import Inventory from './components/pages/Inventory'
import Sync from './components/pages/Sync'
import Onboarding from "./components/pages/Onboarding"
import { Routes, Route } from 'react-router-dom'
import Login from './components/pages/Login'
import 'react-toastify/dist/ReactToastify.css';
import AuthenticatedRoute from "./AuthenticatedRoute";
import UnAuthenticatedRoute from './UnAuthenticatedRoute'
import axios from "axios";
import SignUp from './components/pages/SignUp'
import Forgot from './components/pages/Forgot'
import config from "../src/components/config";
import { useEffect } from 'react'
import { toast } from 'react-toastify';
import NotFound from './components/pages/NotFound'
import auth from './components/services/auth';
import 'bootstrap/dist/js/bootstrap.min.js';

export default function App() {

  const [isLoading, setLoading] = useState(true)

  const Navigate = useNavigate();

  useEffect(() => {
    if (auth.isLogin()) {
      const getUser = async () => {
        try {
          toast.loading('Please wait..');
          const response = await axios.get(`${config.api_url}authenticated`);
          setLoading(false);
          toast.dismiss();
          auth.loginUser(response.data);
        } catch (e) {
          toast.dismiss();
          setLoading(false);
          auth.logout();
          Navigate("/")
        }
      };

      getUser();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (isLoading) {
    return (<h1 className='vertical--center'>Loading..</h1>)
  } else {
    return (
      <>
        <Routes>
          <Route element={<UnAuthenticatedRoute Routes />}>
            <Route path="/register" element={<SignUp />} />
            <Route path="/reset" element={<Forgot />} />
            <Route path="/" element={<Login />} />
          </Route>
          <Route element={<AuthenticatedRoute Routes />}>
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="inventories" element={<Inventory />} />
            <Route path="sync" element={<Sync />} />
            <Route path="/synchhistory" element={<Synchistory />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </>
    );
  }
}