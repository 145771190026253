import React from 'react'
import { useNavigate } from 'react-router-dom'
import logo from "../assets/images/logo.png"
import axios from 'axios';

import config from "../config";
import auth from '../services/auth';

function Navbar() {
    const Navigate = useNavigate();

    const Logout = () => {
        try {
            axios.post(`${config.api_url}auth/logout`);
            auth.logout();
            Navigate("/")
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                <div id="kt_header" className="header align-items-stretch">
                    <div className="container-fluid d-flex align-items-stretch justify-content-between">
                        <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                            <div className="d-flex align-items-stretch" id="kt_header_nav">
                                <div className="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
                                    <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch" id="#kt_header_menu" data-kt-menu="true">
                                        <div className="menu-item menu-lg-down-accordion me-lg-1">
                                            <div className="menu-item">
                                                <div className="menu-link py-3" style={{ paddingLeft: 0 }}>
                                                    <img alt="Logo" src={logo} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-stretch flex-shrink-0 ">
                            <div className="d-flex align-items-stretch ms-1 ms-lg-3">
                                <button type="button" className="btn btn-lg btn-light-primary me-3" onClick={Logout}>
                                    <span className="svg-icon svg-icon-4 me-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor"></rect>
                                            <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor"></path>

                                        </svg>
                                    </span>
                                    Sign Out
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar