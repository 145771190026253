import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import Logo from "../assets/images/logo.png"
import bgimage from "../assets/images/14.png"

import { toast } from 'react-toastify';
import axios from 'axios';
import config from "../config";



function Forgot() {

    const Navigate = useNavigate();


    const [getInputs, setUser] = useState({
        email: ""

    });
    const [loading, setLoading] = useState(false);

    let name, value;
    const handleinputs = (e) => {
        name = e.target.name;
        value = e.target.value;

        setUser({ ...getInputs, [name]: value });
    }

    const Submit = async (e) => {
        e.preventDefault();
        const { email } = getInputs;

        setLoading(true);
        axios.post(`${config.api_url}auth/reset`, {
            email,

        })
            .then(function (response) {
                setLoading(true);
                Navigate('/Login')

            })
            .catch(function (error) {
                setLoading(false);
                toast(error.response.data.message || 'Something went wrong.Try again.')
            });


    }

    return (
        <>
            <div className="d-flex flex-column flex-root">
                <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed full-height" style={{ backgroundImage: `url(${bgimage})` }}>
                    <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                        <div className="mb-12">
                            <img alt="Logo" src={Logo} />
                        </div>
                        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                            <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate" id="kt_password_reset_form">
                                <div className="text-center mb-10">
                                    <h1 className="text-dark mb-3">Forgot Password ?</h1>
                                    <div className="text-gray-400 fw-bold fs-4 ">Enter your email to reset your password.</div>
                                </div>
                                <div className="fv-row mb-10 fv-plugins-icon-container">
                                    <label className="form-label fw-bolder text-gray-900 fs-6 required">Email</label>
                                    <input className="form-control form-control-solid" type="email" placeholder="" name="email"
                                        value={getInputs.email}
                                        onChange={handleinputs}

                                    />
                                    <div className="fv-plugins-message-container invalid-feedback"></div></div>
                                <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                                    <button type="button" disabled={loading} className="btn btn-lg btn-primary fw-bolder me-4" onClick={Submit}>
                                        {loading ? 'Please wait..' : 'Submit'}

                                        {/* <span className="indicator-label">Submit</span>
                                          
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span> */}

                                    </button><Link to="/Login" className="btn btn-lg btn-light-primary fw-bolder">Cancel</Link>
                                </div>
                                <div></div></form>
                        </div>
                    </div>




                </div>




            </div>


        </>
    )
}

export default Forgot