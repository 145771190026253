import React from 'react'
import { useEffect, useState } from 'react'
import config from "../config";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import auth from '../services/auth';
import Navbar from '../layout/Navbar';

function Inventory() {

	const Navigate = useNavigate();

	const [products, setProducts] = useState([]);
	const [collections, setCollections] = useState([]);
	const [action, setAction] = useState(1);

	useEffect(() => {
		const getProducts = async () => {
			try {
				toast.loading('Please wait..');
				const response = await axios.get(`${config.api_url}products`);
				toast.dismiss();
				setProducts(response.data);
			} catch (error) {
				toast.dismiss();
				if (error.response.status === 403) {
					auth.logout();
					Navigate("/")
				}
			}
		};

		const getCollections = async () => {
			try {
				const response = await axios.get(`${config.api_url}collections`);
				console.log(response.data)
				toast.dismiss();
				setCollections(response.data);
			} catch (error) {
				if (error.response.status === 403) {
					auth.logout();
					Navigate("/")
				}
				toast(error.message);
			}
		};

		getProducts();
		getCollections();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let shop = auth.getAuth();
	let shop_title = shop.stores[0].shop.name;
	return (
		<>
			<Navbar />
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover

			/>

			<div className="post d-flex flex-column-fluid">
				<div id="kt_content_container" className="container-xxl">
					<div className="card-body pt-6">
						<div className='full-div-pagina'>
							<ul className="nav nav-pills nav-pills-custom mb-3" role="tablist">
								<li className="nav-item mb-3 me-3 me-lg-6" role="presentation">
									<div onClick={() => setAction(1)} className={`nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden pb-2 ${action === 1 ? 'active' : ''}`}>
										<div className="nav-icon mb-3">
											<i className="fonticon-drive fs-1 p-0"></i>
										</div>
										<span className="nav-text text-gray-800 fw-bold fs-6 lh-1 pb20">Products</span>
										<span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
									</div>
								</li>
								<li className="nav-item mb-3 me-3 me-lg-6" role="presentation">
									<div onClick={() => setAction(2)} className={`nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden pb-2 ${action === 2 ? 'active' : ''}`}>
										<div className="nav-icon mb-3">
											<i className="fonticon-bank fs-1 p-0"></i>
										</div>
										<span className="nav-text text-gray-800 fw-bold fs-6 lh-1 pb20">Collections</span>
										<span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
									</div>
								</li>
							</ul>

							<div className='btn-right btn-right-edit'>
								<Link to='/sync'>Sync Inventory</Link>
							</div>
						</div>

						<div className="tab-content mt-5">
							<h3 className=" d-flex align-items-center text-dark fw-bold my-1 fs-3 ">Store Name -
								<label>{shop_title}</label>
							</h3>

							<div className="tab-pane fade active show" id="kt_stats_widget_16_tab_1" role="tabpanel" aria-labelledby="#kt_stats_widget_16_tab_link_1">

								<div className="card mb-5 mb-xl-10">
									<div className="card-header border-0 cursor-pointer align-item-center" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">

										<div className="card-title m-0">
											<h3 className="fw-bolder m-0">{action === 1 ? 'Products' : 'Collections'}</h3>
										</div>
									</div>
									<div className="collapse show">
										<div className="table-responsive table-responsive2">
											{
												action === 1 && (<table className="table table-row-bordered align-middle gy-4 gs-9">
													<thead className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bolder bg-light bg-opacity-75">
														<tr>
															<th>Sr.No.</th>
															<th>Title</th>
															<th>Price</th>
															<th>Status</th>
														</tr>
													</thead>
													<tbody className="fw-bold text-gray-600 connect-store-data">
														{products.map((product, i) => (
															<tr key={i}>
																<td>{i + 1}</td>
																<td>{product.title}</td>
																<td className="badge badge-light-success">{product.price ? product.price : 0}</td>
																<td>{product.status}</td>
															</tr>
														))}
													</tbody>
												</table>)
											}

											{
												action === 2 && (
													<table className="table table-row-bordered align-middle gy-4 gs-9">
														<thead className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bolder bg-light bg-opacity-75">
															<tr>
																<td>Sr.No.</td>
																<td>Title</td>
															</tr>
														</thead>
														<tbody className="fw-bold text-gray-600 connect-store-data">
															{collections.map((collection, i) => (
																<tr key={i}>
																	<td>{i + 1}</td>
																	<td>{collection.name}</td>
																</tr>
															))}

														</tbody>
													</table>
												)
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Inventory