import React from 'react'

function NotFound() {
  return (
    <> 
    <p>
         This page isn't available. Sorry about that.

           Try searching for something else. 

           This Not Route Error !!!  404 Page not found
    </p>
    
     </>
  )
}

export default NotFound