import { Navigate, Outlet } from "react-router";


const useAuth = () => {
  var isLogin = localStorage.getItem('IsLogin') ? true : false;

  const user = { loggedIn: isLogin };
  return user && user.loggedIn;

};


const UnuthenticatedRoute =() => {
  const isAuth =   useAuth();
  return !isAuth ? <Outlet /> : <Navigate to="/onboarding" />;
};
export default UnuthenticatedRoute;