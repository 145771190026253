import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../layout/Navbar"
import { Link } from 'react-router-dom'
import config from "../config";
import axios from "axios";
import auth from "../services/auth";

class Onboarding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            action: 1,
            accestoken: "",
            url: "",

            product_count: "",
            collection_count: "",
            store_id: "",
            loading: false,

            api: "",
            option: {
                guitar: [],
                shopify: []
            },

            values: [],
            keys: [],
            mapping: {}
        };

        this.getOptions();

    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                url: auth.getStore().shopify_url,
                accestoken: auth.getStore().shopify_access_token
            });
        }, 1000);
    }

    getOptions = async () => {
        try {
            const response = await axios.get(`${config.api_url}mapping`);

            var keys = {};
            response.data.guitar.forEach((element, key) => {
                keys[key] = element.key;
            });

            var values = {};
            response.data.shopify.forEach((element, key) => {
                values[key] = element.key;
            });

            this.setState({
                option: response.data,
                keys: keys,
                values: values
            })


        } catch (error) {
            console.log(error)
        }
    }

    createStore = async () => {
        this.setState({ loading: true });

        const body = {
            shopify_access_token: this.state.accestoken,
            shopify_url: this.state.url,
            platform: "Shopify",
            mapping: this.state.mapping
        };

        try {
            const response = await axios.post(`${config.api_url}stores`, body);

            toast(response.data.message);
            this.getUser();
            this.createInventory(response.data.data.store_id);
        } catch (error) {
            this.setState({
                loading: false,

            });

            toast(error.response.data.message)
        }

    }

    createInventory = async (store_id) => {
        this.setState({ loading: true });
        const body = {
            shopify_access_token: this.state.accestoken,
            shopify_url: this.state.url,
            store_id: store_id
        };

        try {
            await axios.post(`${config.api_url}shopify/sync`, body);
            this.setState({
                action: 5,
                loading: false,
            });
        } catch (error) {
            this.setState({
                loading: false,
            });

            toast(error.response.data.message)
        }
    }

    setTabs = (e) => {
        let el = this;
        if (e === 3) {
            const body = {
                shopify_access_token: this.state.accestoken,
                shopify_url: this.state.url
            };

            if (!this.state.accestoken) {
                toast("please fill all the information carefully")
                return
            }
            if (!this.state.url) {
                toast("please fill all the information carefully")
                return
            }

            el.setState({ loading: true })
            const urls = [`${config.api_url}shopify/products/count`, `${config.api_url}shopify/collections/count`,];

            const promises = urls.map(url => axios.post(url, body));

            Promise.all(promises).then(responses => {
                responses.forEach((response, index) => {
                    if (!index) {
                        this.setState({
                            product_count: response.data.count
                        });
                    } else {
                        this.setState({
                            collection_count: response.data.count
                        });
                    }
                });

                this.setState({
                    loading: false,
                    action: 3
                });

            })
                .catch(function (error) {
                    el.setState({ loading: false })
                    toast(error.response.data.message);
                });

        } else if (e === 4) {
            let mapping_data = {};
            for (var keys_ of Object.keys(this.state.keys)) {
                for (var values_ of Object.keys(this.state.values)) {
                    if (keys_ === values_) {
                        mapping_data[this.state.keys[values_]] = this.state.values[values_]
                    }
                }

            }
            this.setState({
                loading: false,
                mapping: mapping_data,
                action: 4
            });

        } else if (e === 5) {

            this.createStore();

        } else {
            this.setState({
                loading: false,
                action: e
            });
        }
    }

    handleChange = (event) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }

    handleChangeForMapping = (i, e) => {
        this.setState({
            keys: {
                ...this.state.keys,
                [i]: e.target.value
            }
        });
    }

    handleChangeForShopify = (i, e) => {
        this.setState({
            values: {
                ...this.state.values,
                [i]: e.target.value
            }
        });
    }

    getUser = async () => {
        try {
            const response = await axios.get(`${config.api_url}authenticated`);
            auth.loginUser(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    render() {
        let defaultMappings = [];
        let newMappings = [];


        for (let i = 0; i < this.state.option.guitar.length; i++) {
            defaultMappings.push(
                <>
                    {
                        this.state.option.guitar[i].default &&
                        <div className="grid-sec">
                            <div className="fv-row fv-plugins-icon-container">

                                <select
                                    value={this.state.keys[i]}
                                    name={this.state.keys[i]}
                                    // onChange={this.handleChangeForMapping.bind(this, i)}
                                    className="form-select form-select-lg form-select-solid select2-hidden-accessible"
                                    disabled={this.state.option.guitar[i].default}
                                >
                                    {this.state.option.guitar.map((item, g_key) => {
                                        if (item.default) {
                                            return <option value={item.key}
                                            >{item.key}</option>
                                        } else {
                                            return ''
                                        }
                                    })}
                                </select>
                            </div>
                            <div className="fv-row fv-plugins-icon-container">
                                <select
                                    value={this.state.values[i]}
                                    name={this.state.values[i]}
                                    // onChange={this.handleChangeForShopify.bind(this, i)}
                                    className="form-select form-select-lg form-select-solid select2-hidden-accessible"
                                    disabled={this.state.option.shopify[i].default}
                                >
                                    {this.state.option.shopify.map((item, s_key) => {
                                        if (item.default) {
                                            return <option value={item.key}>{item.key}</option>
                                        } else {
                                            return '';
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                    }
                </>
            );
        }

        for (let i = 0; i < this.state.option.guitar.length; i++) {

            console.log(this.state.option.shopify[i].options);
            newMappings.push(
                <>
                    {
                        !this.state.option.guitar[i].default &&
                        <div className="grid-sec">

                            <div className="fv-row fv-plugins-icon-container">

                                <select
                                    value={this.state.keys[i]}
                                    name={this.state.keys[i]}
                                    onChange={this.handleChangeForMapping.bind(this, i)}
                                    className="form-select form-select-lg form-select-solid select2-hidden-accessible"
                                    disabled={this.state.option.guitar[i].disabled}
                                >
                                    {this.state.option.guitar.map((item, g_key) => {
                                        if (!item.default || this.state.option.shopify[i].default) {
                                            return <option value={item.key}
                                                disabled={item.disabled}
                                            >{item.key}</option>
                                        } else {
                                            return '';
                                        }
                                    })}
                                </select>
                            </div>
                            <div className="fv-row fv-plugins-icon-container">
                                {
                                    this.state.option.shopify[i].options != undefined && (
                                        <select
                                            value={this.state.values[i]}
                                            name={this.state.values[i]}
                                            onChange={this.handleChangeForShopify.bind(this, i)}
                                            className="form-select form-select-lg form-select-solid select2-hidden-accessible"
                                        >
                                            {this.state.option.shopify[i].options.map((item) => {
                                                return <option value={item}>{item}</option>
                                            })}
                                        </select>
                                    )
                                }


                                {
                                    this.state.option.shopify[i].text != undefined && (
                                        <input type="number"
                                            placeholder="Enter value"
                                            value={this.state.values[i]}
                                            name={this.state.values[i]}
                                            onChange={this.handleChangeForShopify.bind(this, i)}
                                            className="form-select form-select-lg form-select-solid select2-hidden-accessible"
                                        />
                                    )
                                }


                                {/* <select
                                    value={this.state.values[i]}
                                    name={this.state.values[i]}
                                    onChange={this.handleChangeForShopify.bind(this, i)}
                                    className="form-select form-select-lg form-select-solid select2-hidden-accessible"
                                    disabled={this.state.option.shopify[i].default}
                                >
                                    {this.state.option.shopify.map((item, s_key) => {
                                        if (!item.default || this.state.option.shopify[i].default) {
                                            return <option value={item.key}
                                                disabled={item.disabled}
                                            >{item.key}</option>
                                        } else {
                                            return '';
                                        }
                                    })}
                                </select> */}
                            </div>
                        </div>
                    }
                </>
            );
        }

        return (
            <>
                <ToastContainer />
                <Navbar />

                <div className="container-xxl container-main-sec-container">
                    <div className="card">
                        <div className="card-body pt-6">
                            <div className="stepper stepper-links d-flex flex-column pt-15">
                                <div className="stepper-nav mb-5">
                                    <div className={this.state.action === 1 ? 'stepper-item current' : 'stepper-item'}>
                                        <h3 className="stepper-title" >CMS Type</h3>
                                    </div>
                                    <div className={this.state.action === 2 ? 'stepper-item current' : 'stepper-item'}>
                                        <h3 className="stepper-title" >Configuration</h3>
                                    </div>
                                    <div className={this.state.action === 3 ? 'stepper-item current' : 'stepper-item'}>
                                        <h3 className="stepper-title" >Mapping</h3>
                                    </div>

                                    <div className={this.state.action === 4 ? 'stepper-item current' : 'stepper-item'}>
                                        <h3 className="stepper-title" >Review</h3>
                                    </div>
                                    <div className={this.state.action === 5 ? 'stepper-item current' : 'stepper-item'}>
                                        <h3 className="stepper-title" >Completed</h3>
                                    </div>
                                </div>
                                <div className="mx-auto mw-800px w-100 pt-15 pb-10 fv-plugins-bootstrap5 fv-plugins-framework" >
                                    {
                                        this.state.action === 1 && (<div className="w-100">
                                            <div className="pb-10 pb-lg-5">
                                                <h2 className="fw-bold d-flex align-items-center text-dark">Select Your CMS
                                                    <div className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" aria-label="Billing is issued based on your selected account type" data-kt-initialized="1" data-bs-original-title="" title="">

                                                    </div>
                                                </h2>

                                            </div>
                                            <div className="fv-row fv-plugins-icon-container">
                                                <div className="row">
                                                    <div className="col-lg-4 ">
                                                        <input type="radio" className="btn-check" name="account_type" checked={true} onChange={e => { }} value="personal" id="kt_create_account_form_account_type_personal" />
                                                        <label className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10 ">
                                                            <span className="svg-icon svg-icon-3x me-5">
                                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 108.44 122.88" space="preserve">
                                                                    <g><path className="st0 " d="M94.98,23.66c-0.09-0.62-0.63-0.96-1.08-1c-0.45-0.04-9.19-0.17-9.19-0.17s-7.32-7.1-8.04-7.83 c-0.72-0.72-2.13-0.5-2.68-0.34c-0.01,0-1.37,0.43-3.68,1.14c-0.38-1.25-0.95-2.78-1.76-4.32c-2.6-4.97-6.42-7.6-11.03-7.61 c-0.01,0-0.01,0-0.02,0c-0.32,0-0.64,0.03-0.96,0.06c-0.14-0.16-0.27-0.32-0.42-0.48c-2.01-2.15-4.58-3.19-7.67-3.1 c-5.95,0.17-11.88,4.47-16.69,12.11c-3.38,5.37-5.96,12.12-6.69,17.35c-6.83,2.12-11.61,3.6-11.72,3.63 c-3.45,1.08-3.56,1.19-4.01,4.44C9.03,39.99,0,109.8,0,109.8l75.65,13.08l32.79-8.15C108.44,114.73,95.06,24.28,94.98,23.66 L94.98,23.66z M66.52,16.63c-1.74,0.54-3.72,1.15-5.87,1.82c-0.04-3.01-0.4-7.21-1.81-10.83C63.36,8.47,65.58,13.58,66.52,16.63 L66.52,16.63z M56.69,19.68c-3.96,1.23-8.29,2.57-12.63,3.91c1.22-4.67,3.54-9.33,6.38-12.38c1.06-1.14,2.54-2.4,4.29-3.12 C56.38,11.52,56.73,16.39,56.69,19.68L56.69,19.68z M48.58,3.97c1.4-0.03,2.57,0.28,3.58,0.94C50.55,5.74,49,6.94,47.54,8.5 c-3.78,4.06-6.68,10.35-7.83,16.43c-3.6,1.11-7.13,2.21-10.37,3.21C31.38,18.58,39.4,4.23,48.58,3.97L48.58,3.97z"></path><path className="st1" d="M93.9,22.66c-0.45-0.04-9.19-0.17-9.19-0.17s-7.32-7.1-8.04-7.83c-0.27-0.27-0.63-0.41-1.02-0.47l0,108.68 l32.78-8.15c0,0-13.38-90.44-13.46-91.06C94.9,23.04,94.35,22.7,93.9,22.66L93.9,22.66z"></path><path className="st2" d="M57.48,39.52l-3.81,14.25c0,0-4.25-1.93-9.28-1.62c-7.38,0.47-7.46,5.12-7.39,6.29 c0.4,6.37,17.16,7.76,18.11,22.69c0.74,11.74-6.23,19.77-16.27,20.41c-12.05,0.76-18.69-6.35-18.69-6.35l2.55-10.86 c0,0,6.68,5.04,12.02,4.7c3.49-0.22,4.74-3.06,4.61-5.07c-0.52-8.31-14.18-7.82-15.04-21.48c-0.73-11.49,6.82-23.14,23.48-24.19 C54.2,37.88,57.48,39.52,57.48,39.52L57.48,39.52z"></path></g></svg>
                                                            </span>
                                                            <span className="d-block fw-semibold text-start">
                                                                <span className="text-dark fw-bold d-block fs-4 mb-2">Shopify</span>
                                                                <span className="text-muted fw-semibold fs-6">Create an ecommerce website backed by powerful tools.</span>
                                                            </span>
                                                        </label>
                                                        <div className="fv-plugins-message-container invalid-feedback"></div><div className="fv-plugins-message-container invalid-feedback"></div></div>
                                                    <div className="col-lg-4">
                                                        <input type="radio" className="btn-check" name="account_type" value="corporate" id="kt_create_account_form_account_type_corporate" />
                                                        <label className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center" >
                                                            <span className="svg-icon svg-icon-3x me-5">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1129.16 1333.33" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd"><path d="M638.91 399.93v759.5l-74.69 45.65-74.75-45.89V400.52L295.93 519.68v649.51l268.28 164.15 270.55-165.32V519.27L638.89 399.94zM564.22 0L0 341.84v649.59l146.54 86.33V428.11l417.8-254.04 418.19 253.67 1.72.98-.19 648.07 145.1-85.36V341.84L564.23 0z" fill="#f26322" ></path></svg>
                                                            </span>
                                                            <span className="d-block fw-semibold text-start">
                                                                <span className="text-dark fw-bold d-block fs-4 mb-2">Magento</span>
                                                                <span className="text-muted fw-semibold fs-6">Open-source e-commerce platform written in PHP   <strong>(Comming Soon)</strong></span>
                                                            </span>
                                                        </label>
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <input type="radio" className="btn-check" name="account_type" value="corporate" id="kt_create_account_form_account_type_corporate" />
                                                        <label className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center" >
                                                            <span className="svg-icon svg-icon-3x me-5">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333334 333334" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd">
                                                                    <path d="M108046 255750c2661-1185 5358-2275 8080-3297 3073-1155 6170-2241 9310-3186 3907-1177 7848-2242 11841-3090 5388-1146 10837-1988 16320-2522-3099 9721-6490 19347-10167 28865 152 1858 249 3720 292 5583 7557-1494 15242-2247 22945-2249 7853 0 15521 784 22945 2249 43-1863 140-3725 292-5583-3678-9517-7068-19143-10167-28865 5488 533 10936 1379 16320 2522 3992 848 7932 1913 11840 3090 3141 945 6238 2031 9311 3186 2722 1023 5420 2113 8082 3298 19239 8554 36379 21206 50221 37072 35391-30561 57822-75725 57822-126156C333333 74620 258713 0 166666 0S-1 74620-1 166667c0 50431 22431 95594 57822 126156 13843-15866 30983-28519 50224-37073z" fill="#a5dbe8" fillRule="nonzero"></path>
                                                                    <path className="fil1" d="M225291 255752l10346 35577 2935 138c1905 0 3245 501 3245 2406 0 3167 164 6725 467 9832 2578 2189 5718 4657 8098 7056 8913-5191 17325-11195 25130-17937-13842-15866-30982-28518-50221-37072zm-20231-356l12149-2943c-3073-1155-6170-2241-9311-3186-3907-1177-7848-2242-11840-3090-5389-1146-10837-1988-16320-2522 3099 9721 6489 19347 10167 28865-152 1858-249 3720-292 5583 8608 1698 17072 4648 24897 8109l-9450-30817z"></path>
                                                                    <path className="fil2" d="M82952 310761c1935 1128 3889 2228 5876 3279 695-3340 1807-7967 2143-11404-2578 2189-5639 5726-8020 8125zm131354-24856c-7917-3507-16199-6124-24694-7802-7557-1494-15242-2247-22945-2249-7854 0-15521 784-22945 2249-8607 1696-17587 4271-25410 7730l-832 5184h98378l-1551-5112z"></path>
                                                                    <path className="fil2" d="M243556 309955c674 3385 827 4060 949 4085 1981-1053 3940-2146 5876-3279-2406-2427-4913-4750-7516-6965l690 6159z"></path>
                                                                    <path className="fil1" d="M90667 303623c305-3107 525-5876 525-9044 0-1904 1306-3124 3211-3124l3124-109 10519-35595c-19240 8554-36381 21207-50224 37073 7805 6742 16217 12747 25130 17937 2380-2400 4861-4699 7437-6888l278-250z"></path>
                                                                    <path className="fil1" d="M90667 303623c305-3107 525-5876 525-9044 0-1904 1306-3124 3211-3124l3124-109 10519-35595c-19240 8554-36381 21207-50224 37073 7805 6742 16217 12747 25130 17937 2380-2400 4861-4699 7437-6888l278-250z"></path><path className="fil3" d="M214306 285905l1551 5112h20122l-10689-35266c-2661-1179-5356-2279-8082-3298l-12149 2943 9246 30509z"></path><path className="fil4" d="M97707 298140c106-74 213-148 317-224h-302c-3 74-11 150-15 224z"></path><path className="fil3" d="M235612 297916h-303l316 224c-3-74-10-150-13-224z"></path>
                                                                    <path className="fil3" d="M235266 297401l-68742 78 143 35854c25583 0 49813-5780 71475-16082-1414-6213-1556-12906-1786-19419-104-76-1090-431-1090-431z"></path>
                                                                    <path className="fil4" d="M166556 297628s-69121-87-69227-13c-229 6514-1573 14243-2138 19637 21663 10302 45892 16082 71475 16082l39-35590-150-116z"></path>
                                                                    <path fill="#776452" fillRule="nonzero" d="M166667 294467h1v3449h-1z"></path>
                                                                    <path className="fil3" d="M218222 271042l35 108-35-108s-1690-1340-3835-3807c2144 2467 3835 3807 3835 3807z"></path>
                                                                    <path className="fil6" d="M269148 120245v8-8zm6707 47986l1-50-1 50z"></path>
                                                                    <path className="fil7" d="M275857 168181l-1-45z"></path>
                                                                    <path className="fil8" d="M275670 165585c5 44 18 83 23 128 4 27 12 52 16 78v11c80 637 109 1302 124 1972 3 124 24 240 24 362l1 45-1 50c-2 133-24 257-25 388-9 646-50 1292-121 1935v9l-18 95c-5 51-21 96-28 146-87 664-212 1323-374 1973l-2 9v1l23-26 9-30v1l4362-4566-4397-4603 1 9c164 642 289 1316 383 2013z"></path>
                                                                    <path className="fil2" d="M275664 170804c12-86 34-163 46-250 77-625 106-1277 121-1935 1-148 25-288 25-438 0-139-21-269-24-407-8-659-49-1317-124-1972-11-76-30-142-39-216-89-678-217-1350-383-2013-57-224-137-423-203-639-394-1305-932-2457-1570-3414-244-364-511-712-800-1042-263-291-552-559-862-801-115-87-236-148-353-224-359-236-748-424-1156-558-385-128-788-198-1194-206-409 0-804 86-1194 206-409 134-798 322-1157 558-115 76-237 135-350 223-536 418-1007 914-1398 1470-91 122-180 248-265 375-638 957-1177 2109-1570 3414l-50 156c-174 599-310 1229-423 1876-34 198-80 388-107 591-104 724-157 1479-170 2253-2 107-18 208-18 315h26c0 6349 3004 11495 6708 11495 638 0 1251-167 1836-452 350-172 681-381 987-624 126-102 245-224 366-339 295-276 577-589 843-936 185-243 367-487 537-759 108-173 207-361 307-546 187-346 365-707 524-1090 60-138 121-272 176-414 204-532 383-1095 534-1685 160-629 280-1291 374-1973zm-6274-4152c-1389 0-2516-1931-2516-4311 0-2379 1126-4310 2516-4310 1389 0 2514 1931 2514 4310 0 2380-1125 4311-2514 4311z">
                                                                    </path>
                                                                    <path className="fil6" d="M269390 158030c-1389 0-2516 1931-2516 4310 0 2380 1126 4311 2516 4311 1389 0 2514-1931 2514-4311 0-2379-1125-4310-2514-4310z"></path>
                                                                    <path className="fil9" d="M263214 162934c393-1305 932-2457 1570-3414 86-130 173-251 265-375 390-557 861-1053 1398-1470 113-87 236-147 350-223 359-237 748-425 1157-558 388-122 785-206 1194-206s806 83 1194 206c408 134 797 322 1156 558 117 76 239 137 353 224 299 229 586 500 862 801 288 330 555 678 800 1042 638 957 1176 2109 1570 3414v2l-5935-27183-5936 27182h1z"></path>
                                                                    <path className="fil2" d="M190494 167927c-8-547-43-1087-104-1618-1-7-4-15-4-22-61-520-149-1036-261-1547l-11-39c-111-498-248-991-407-1476l-13-33c-159-481-343-954-550-1417-2-9-9-19-13-29-1654-3681-4655-6589-8387-8125l-46-17c-908-370-1848-654-2810-848-25-4-48-5-73-10-992-196-2001-295-3012-296-1014 0-2000 104-2959 288l-102 16c-955 190-1875 469-2759 823l-70 27c-894 363-1752 808-2564 1330-811 524-1573 1119-2274 1776l-17 54-59 13c-1122 1067-2082 2293-2850 3638l-29 48c-234 413-446 843-642 1280-20 47-47 91-66 138-178 405-332 824-475 1246-28 83-64 162-90 244-125 394-226 800-323 1204-25 117-63 229-87 345-83 401-141 811-194 1222-16 126-43 248-56 374-164 1559-92 3134 213 4672 3 19 4 42 9 63 197 980 486 1926 857 2831 6 13 8 26 15 38 751 1817 1836 3478 3198 4896 1068 1113 2297 2068 3648 2831l20 12c422 237 858 450 1302 648 37 16 72 37 109 52 411 180 837 335 1266 479 73 25 143 56 216 80 396 126 805 228 1214 322 111 26 217 63 329 86 397 82 806 138 1218 191 124 15 246 43 372 56 3667 386 7352-533 10409-2596 1672-1130 3112-2569 4242-4241 1751-2593 2685-5651 2682-8780 0-38-5-73-5-108-2-40-7-78-8-119zm-15690-1840c-3258 0-5899-2642-5898-5900 0-3258 2642-5899 5900-5898 3258 0 5898 2642 5898 5900s-2642 5899-5900 5898z"></path>
                                                                    <path className="fil6" d="M174804 154289c-3257-3-5900 2636-5903 5894-3 3257 2636 5900 5893 5903h10c3257-3 5896-2646 5894-5903-3-3254-2640-5891-5894-5894z"></path>
                                                                    <path className="fil7" d="M164000 156776l59-13 17-54 10815-33076v-7741l-2-87c-6104-1264-12321-1905-18554-1914-2116 0-4215 90-6301 232-304 21-608 39-913 63-10856 824-21546 3654-31444 8172-8488 3876-16375 8982-23465 15035v2l-12 10c-1897 1624-3735 3317-5509 5074-333 326-672 648-1002 979-1678 1692-3300 3437-4866 5233-340 392-674 792-1010 1187-1580 1854-3101 3757-4560 5707-121 164-235 336-354 500-1384 1890-2711 3822-3979 5792-249 384-504 763-748 1151-1028 1632-1985 3307-2936 4987h49267l45496-11239z"></path>
                                                                    <path className="fil10" d="M205593 153055c100-1291 182-2580 248-3871 90-1747 151-3497 185-5246 26-1358 22-2717 15-4076 0-392 9-784 5-1177-40-3507-169-7011-387-10511l-30-20-13-25c-1302-748-2605-1493-3910-2234l-91-52-1020-577-18-10-707-396-139-78c-170-95-342-188-514-280-2265-1176-4575-2261-6926-3254-5603-2366-13150-4440-17401-5441v7828l10720 33129 12 27c1107 1055 2059 2267 2822 3603v1c250 437 479 889 686 1352 5 10 10 19 13 29 207 461 389 933 550 1417l13 33c159 483 296 975 407 1476l11 39c112 507 199 1024 261 1547 0 8 3 16 4 22 61 531 96 1071 104 1618l40 89h13126c881-4817 1514-9676 1897-14558 9-134 25-269 35-402z"></path>
                                                                    <path className="fil11" d="M222759 141341c-9 15-16 31-24 46l24-44v-2z"></path>
                                                                    <path className="fil9" d="M164076 156710c705-661 1469-1257 2282-1780 809-520 1664-963 2555-1326 24-10 48-17 70-27 892-359 1816-634 2759-823l102-16c975-189 1966-286 2959-288 1031 0 2037 104 3012 296 25 5 48 6 73 10 977 196 1933 486 2856 865 1808 746 3463 1820 4879 3169l-12-27-10720-33129-10815 33076z"></path>
                                                                    <path className="fil8" d="M160267 174081c-376-914-662-1862-857-2831-5-21-6-43-9-63-305-1538-377-3113-213-4672 13-126 40-247 56-374 54-411 111-822 194-1222 25-116 63-228 87-345 96-405 198-810 323-1204 27-82 61-160 90-244 143-422 297-841 475-1246 20-47 46-91 67-138 195-437 407-867 642-1280l29-48c768-1345 1728-2570 2850-3638l-45496 11239h-87l45063 10999c-1362-1418-2447-3079-3198-4896-6-12-9-25-14-38z"></path>
                                                                    <path className="fil10" d="M262634 165557c29-203 72-393 107-591 113-647 248-1278 423-1876 20-68 28-144 48-211l5936-27182-2-15499c-4850-4006-10044-6196-15452-6196-6833 0-13205 3773-17969 8433-5213 5100-9242 11517-12500 17992-150 298-304 585-452 884 1004 870 1998 1751 2978 2648 2216 2034 4393 4110 6531 6225 997 985 1976 1990 2958 2991 1042 1061 2085 2120 3111 3198 1876 1971 3726 3967 5549 5987 1565 1726 3112 3467 4642 5222l2 3c257 268 437 461 506 539h13388c0-108 22-207 24-315 13-774 66-1529 170-2253zm-39861-24174l-14-40-24 44 59 57c-6-20-13-41-21-61z"></path>
                                                                    <path className="fil1" d="M61166 185121l120-299c1798-4665 3871-9218 6208-13637 362-687 716-1378 1091-2059 207-376 441-735 652-1111 951-1680 1908-3355 2936-4987 244-389 499-767 748-1151 1268-1970 2595-3902 3979-5792 120-164 233-336 354-500 1459-1950 2980-3854 4560-5707 336-396 669-794 1010-1187 1566-1796 3188-3541 4866-5233 331-331 668-654 1002-979 1774-1757 3612-3450 5509-5074l12-12c7488-25083 31668-40497 58779-40497 10911 0 21763 3144 31062 8833 9539 5835 16043 11535 21093 21500l76 150c128 253 271 495 396 750l13 25 30 20c4241 2624 8280 5559 12085 8782 1280 1080 2535 2189 3766 3326 406 372 824 727 1225 1106 8-16 14-31 24-46 4-11 9-21 14-31 138-283 274-570 413-849 3820-24111 15958-37257 28502-37257 9456 0 16330 5638 21521 20815l54 163 13 19c1531 1670 2960 3430 4280 5271l-3-2c28 37 56 70 83 108l-2-1 2 1c74 220 138 446 209 667v-1l-195-791c-15606-46200-59303-79463-110772-79463-64557 0-116891 52333-116891 116891 0 12727 2034 24979 5810 36380l15-67c1328-6150 3117-12191 5352-18072z"></path>
                                                                    <path className="fil6" d="M279397 168171l-4077 4561v-1l-9 30c-155 600-345 1160-555 1700-55 142-116 276-176 414-156 372-331 736-525 1090-100 185-199 372-307 546-169 271-352 517-536 759-255 335-537 648-844 936-121 113-239 237-366 339-306 243-637 452-987 624-585 285-1198 452-1836 452-3704 0-6708-5146-6708-11495h-13460l8 11c2529 2922 5018 5880 7466 8870 2353 2875 4677 5774 6972 8696l338 428c2174 2776 4329 5567 6464 8372l622 813c2267 2985 4520 5981 6759 8988 3939-11661 6008-23871 6130-36178l-3784-143-590 189z"></path>
                                                                    <path className="fil2" d="M149121 114187c305-24 609-42 913-63 2097-149 4198-226 6301-232 6339 0 12531 678 18554 1914h2c4251 1001 11798 3076 17401 5441 2351 993 4661 2078 6926 3254l514 280 139 78c210 117 439 244 707 396l18 10 1020 577 91 52c1440 816 3034 1732 3643 2081l156 90 111 64c-125-255-268-497-396-750l-76-150c-5050-9965-12823-18512-22362-24346-9300-5689-20151-8833-31064-8833-27109 0-50020 18261-57508 43344 7090-6053 14976-11159 23465-15035 9898-4518 20589-7348 31444-8172z"></path>
                                                                    <path className="fil7" d="M277637 129577l-1-1 1 1c-27-38-56-72-83-108l3 2c-1320-1841-2749-3601-4280-5271l-38-46c-652-708-1326-1396-2020-2064-256-246-522-461-781-697-428-389-854-791-1289-1148v15507l5937 27185v-2c65 211 143 409 200 628l4112 4608 41-46h4304c5-398 30-792 30-1191v-7c-2-12466-2002-24852-5924-36685v1c-72-220-135-446-210-667z"></path>
                                                                    <path className="fil2" d="M235727 122435c4764-4660 11135-8433 17969-8433 5407 0 10602 2190 15452 6196l2 47v8-8c435 357 861 759 1289 1148 259 236 525 451 781 697 694 667 1368 1356 2020 2064l38 46c-3-6-7-11-12-16l-1-1-54-164c-5191-15177-13568-25038-23021-25038-12545 0-23182 17370-27002 41480l40-34c3258-6475 7287-12892 12500-17992zm-16952 148185s-1689-1339-3833-3806c-998-1141-1928-2340-2785-3590-274-398-544-813-814-1240-811-1283-1458-2844-2172-4387-826-1787-828-2975-1302-4639-349-1226-626-2470-830-3728-329-2035-461-4096-394-6156 36-1189 159-2419 337-3672 177-1244 419-2478 726-3697 945-3780 2579-7806 5129-12056l648-1456c833-1388 1018-1327 1810-2664 11151-18819 17167-33050 14224-54961-323-2245-689-4483-1099-6713-257 1617-528 3231-814 4842-111 500-414 1043-529 1383-4844 14336-12469 24881-23440 36789-1528 1652-3074 3286-4638 4902-2
                                                                       794 2889-5618 5749-8470 8581-1187 1181-2398 2379-3630 3600-1131 1120-2539 3514-4219 5278-1037 2902-2215 6176-3211 9619-449-170-878-323-1335-500-21312-8243-35582-19258-35582-19258s10979 14296 29476 27715c1701 1232 3465 2374 5285 3422-763 12078 4565 23673 28616 27131 4067 585 8230 557 12424 85-42-125 235-122 212-191-23-70 224-256 224-256l-16-379z"></path>
                                                                    <path d="M205910 239530c21352 3445 52952 2880 85757-12479 791-370 1661 604 2452 216 0 0-22421 22900-49197 35846-783 378-1555 780-2345 1139-2587 1182-5219 2212-7861 3163-5181 1862-10431 3275-15643 3927-240 31-480 77-719 103-33-98-65-197-98-296l-35-108s-1690-1340-3835-3807c-999-1140-1928-2339-2784-3590-1195-1736-2241-3570-3126-5482-748-1615-1369-3285-1858-4996-919-3211-1332-6546-1225-9884 45-1229 157-2455 337-3672l180-81z" fill="#9e2154" fillRule="nonzero"></path>
                                                                    <path className="fil11" d="M190607 215222c2853-2831 5677-5692 8471-8581 1564-1617 3110-3251 4638-4902 10970-11907 19023-23051 23868-37387 113-340 228-788 339-1292l52-233c113-535 224-1130 335-1801 44-462 1494-1394 1534-1828-1215-6242-4014-12805-7070-17815 7 20 15 41 21 61l-59-57c-401-379-819-733-1225-1106-1230-1137-2486-2245-3766-3326-3805-3223-7844-6158-12085-8782 214 3507 350 7011 387 10511 6 393-5 785-5 1177 7 1358 11 2717-15 4076-34 1750-95 3498-185 5246-66 1292-148 2581-248 3871-11 134-26 268-35 402-383 4882-1017 9741-1897 14558l-1542 7291c-3220 13055-9761 24896-15138 37122-1426 3242-2779 6521-3965 9861l-336 943c1412-1490 2844-2960 4297-4410 1233-1220 2444-2419 3630-3600z"></path>
                                                                    <path className="fil1" d="M120050 285434c7824-3461 15065-5634 23672-7331-43-1863-140-3725-292-5583 3677-9518 7067-19144 10167-28865-5483 534-10932 1376-16320 2522-3994 848-7934 1913-11841 3090-3139 945-6237 2031-9310 3186l12150 2943-9776 30685 1551-647z"></path>
                                                                    <path d="M270259 194503c-2135-2806-4289-5597-6464-8372l-338-428c-2323-2958-4676-5893-7059-8803-2420-2954-4880-5875-7379-8763-157-184-315-370-475-552-2059-2365-4150-4702-6271-7011-1294-1411-2600-2811-3920-4198-1029-1074-2066-2140-3111-3198-982-1001-1961-2006-2958-2991-2139-2115-4317-4190-6531-6225-981-896-1974-1780-2979-2650-6 10-10 20-14 31 2112 6070 4522 12216 5729 18547 314 1647 573 3249 784 4814 2943 21910-4078 36216-15228 55035-817 1375-1636 2748-2459 4120-2550 4252-4182 8277-5129 12057-306 1219-548 2453-725 3697 21866 3529 54746 4186 88388-12344 0 0-6598-9697-16525-23026-2223-2986-4461-5961-6712-8926-206-272-415-543-622-813z" fill="#df0067" fillRule="nonzero"></path>
                                                                    <path className="fil3" d="M117337 291565l10937-36169-12150-2943c-2721 1022-5418 2112-8080 3297l-10832 35815 12093 189 8031-189z"></path>
                                                                    <path className="fil6" d="M125026 275917c1067 410 2154 780 3237 1159 12409 4355 25466 6576 38617 6572 14961 8 29782-2864 43656-8461 714-287 1413-602 2119-902 1144-486 2281-990 3410-1512 1008-469 2016-933 3008-1430-240 31-479 77-719 103-4194 472-8358 500-12424-85-24051-3458-29379-15054-28616-27131-1820-1047-3584-2189-5285-3422-18497-13419-29476-27715-29476-27715s14269 11015 35582 19258c457 177 885 330 1335 500 996-3443 2174-6716 3211-9619l336-943c1186-3340 2539-6619 3965-9861 5378-12227 11918-24068 15138-37122l1542-7291h-13154l-1 2 1 26-5 2c0 35 5 70 5 108 3 3129-931 6187-2682 8780-1707 2522-4106 4497-6909 5687-2441 1033-5106 1429-7742 1150-126-13-247-41-372-56-411-53-820-109-1217-191-112-25-219-60-330-86-409-94-818-195-1213-322-73-25-143-55-216-80-428-144-854-299-1266-479-38-15-73-35-109-52-444-196-878-413-1302-648l-20-12c-1348-760-2577-1714-3648-2831l-45062-10999H69240c-211 375-445 735-652 1111-375 681-729 1372-1091 2059-1209 2283-2346 4603-3411 6956-996 2200-1929 4428-2797 6681l-120 299c-2235 5881-4024 11922-5352 18072 7742 23615 22806 43906 42498 58208 573 415 1177 785 1758 1192 3332 2339 6784 4503 10342 6483 4707 2618 9590 4904 14615 6841z"></path>
                                                                    <path d="M242943 303873c-315-3126-479-6265-489-9406 0-1904-1544-3449-3448-3449H94329c-1905 0-3448 1544-3449 3449 0 3167-186 6299-490 9406-337 3414-858 6808-1561 10167 2098 1109 4212 2189 6363 3212 1438-6277 2281-12676 2515-19112 4-74 12-150 15-224h137891c3 74 11 150 13 224 236 6435 1079 12835 2517 19112 2144-1026 4265-2096 6363-3212-703-3358-1224-6752-1563-10167z" fill="#8a7460" fillRule="nonzero"></path>
                                                                </svg>
                                                            </span>
                                                            <span className="d-block fw-semibold text-start">
                                                                <span className="text-dark fw-bold d-block fs-4 mb-2">PrestaShop</span>
                                                                <span className="text-muted fw-semibold fs-6"> Open source e-commerce platform written in PHP <strong>(Comming Soon)</strong></span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    }

                                    {
                                        this.state.action === 2 && (<div className="w-100">
                                            <div className="fv-row mb-10 fv-plugins-icon-container">
                                                <label className="form-label required">Shopify url</label>
                                                <input name="url" className="form-control form-control-lg form-control-solid"
                                                    value={this.state.url} onChange={this.handleChange}
                                                    required
                                                ></input>
                                                <div className="fv-plugins-message-container invalid-feedback"></div>
                                                <div className="fv-plugins-message-container invalid-feedback">
                                                </div>
                                            </div>
                                            <div className="fv-row mb-10 fv-plugins-icon-container">
                                                <label className="d-flex align-items-center form-label">
                                                    <span className="required">Shopify Access Token</span>
                                                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="<div className='p-4 rounded bg-light'> <div className='d-flex flex-stack text-muted mb-4'> <i className='fas fa-university fs-3 me-3'></i> <div className='fw-bold'>INCBANK **** 1245 STATEMENT</div> </div> <div className='d-flex flex-stack fw-semibold text-gray-600'> <div>Amount</div> <div>Transaction</div> </div> <div className='separator separator-dashed my-2'></div> <div className='d-flex flex-stack text-dark fw-bold mb-2'> <div>USD345.00</div> <div>KEENTHEMES*</div> </div> <div className='d-flex flex-stack text-muted mb-2'> <div>USD75.00</div> <div>Hosting fee</div> </div> <div className='d-flex flex-stack text-muted'> <div>USD3,950.00</div> <div>Payrol</div> </div> </div>" data-kt-initialized="1" data-bs-original-title="" title=""></i>
                                                </label>
                                                <input name="accestoken" className="form-control form-control-lg form-control-solid"
                                                    value={this.state.accestoken} onChange={this.handleChange}
                                                    required

                                                />
                                                <div className="fv-plugins-message-container invalid-feedback"></div><div className="fv-plugins-message-container invalid-feedback"></div>
                                            </div>

                                        </div>)
                                    }

                                    {
                                        this.state.action === 3 && (
                                            <div className="w-100">
                                                <div className="accordion" id="accordionExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                Auto mapped columns
                                                            </button>
                                                        </h2>
                                                        <div className='data-div'>
                                                            <p>
                                                                <br />
                                                                Our algorithm has detected the correct mapping for some fields and they are already mapped with guitarTrader Database. These columns need to action from you.
                                                                <br />
                                                                <br />
                                                            </p>
                                                        </div>
                                                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <div className="label-class">
                                                                    <label className="form-label required">Guitar Trader Fields</label>
                                                                    <label className="form-label required">Shopify  Fields</label>
                                                                </div>
                                                                {defaultMappings}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingTwo">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                Map Extra Columns
                                                            </button>
                                                        </h2>
                                                        <div className='data-div'>
                                                            <p>
                                                                Here are some fields in your shopify products that we could not map automatically to GuitarTrader product columns. Please select he correct column from the right side dropdowns of guitarTrader Product details for each shopify product column.
                                                            </p>
                                                            <div className='full-title-div'>
                                                                <div className='product-title'>
                                                                    <h2>GT Product Columns</h2>
                                                                </div>
                                                                <div className='product-title'>
                                                                    <h2>Shopify Product Columns</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                {newMappings}
                                                            </div>
                                                        </div>
                                                        {/* <div className='button-sec'>
                                                            <button className='btn btn-lg btn-primary'>
                                                                Save Mapping
                                                            </button>
                                                        </div> */}
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        this.state.action === 4 && (<div className="w-100">
                                            <div className="pb-10 pb-lg-12">
                                                <h2 className="fw-bold text-dark">Review</h2>
                                                <div className="text-muted fw-semibold fs-6">Your Store is been connected, please review the added details:-
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-lg-5 fw-bold fs-4">Products Count</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bolder fs-6 text-muted">{this.state.product_count}</span>
                                                </div>
                                            </div>

                                            <div className="row mb-7">
                                                <label className="col-lg-5 fw-bold fs-4">Collections count</label>
                                                <div className="col-lg-7 fv-row">
                                                    <span className="fw-bold fs-6 text-muted">{this.state.collection_count}</span>
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                    {
                                        this.state.action === 5 && (<div className="w-100">
                                            <div className="pb-8 pb-lg-10">

                                                <div className="text-muted fw-semibold fs-6">
                                                </div>
                                                <div className="notice d-flex bg-light-warning border-warning border border-dashed p-6">
                                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                                            <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                                                            <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                                        </svg>
                                                    </span>
                                                    <div className="d-flex flex-stack flex-grow-1">
                                                        <div className="fw-semibold">
                                                            <h2 className="fw-bold text-dark">You Are Done!</h2>
                                                            <div className="fs-6 text-gray-700">The store synch has been activated. You can relax, synced Inventory will be shown once its completed.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    }

                                    <div className="d-flex flex-stack pt-15">


                                        <div className="mr-2">

                                            {
                                                this.state.action > 1 && this.state.action < 5 && (
                                                    <button type="button" className="btn btn-lg btn-light-primary me-3" onClick={() => this.setTabs(this.state.action - 1)}>


                                                        Back

                                                    </button>
                                                )
                                            }


                                        </div>
                                        <div>
                                            {
                                                (this.state.action === 4 && !auth.getStore().shopify_access_token) && (

                                                    <button type="button" disabled={this.state.loading} className="btn btn-lg btn-primary" onClick={() => this.setTabs(this.state.action + 1)}>
                                                        {this.state.loading ? 'Please wait' : 'Sync-Shopify'}

                                                    </button>
                                                )
                                            }

                                            {
                                                (this.state.action === 4 && auth.getStore().shopify_access_token) && (

                                                    <Link to="/inventories" type="button" className="btn btn-lg btn-primary">
                                                        Product Inventory
                                                        <span className="svg-icon svg-icon-4 ms-1 me-0">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect>
                                                                <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path>
                                                            </svg>
                                                        </span>
                                                    </Link>
                                                )
                                            }

                                            {
                                                this.state.action < 4 && (

                                                    <button type="button" disabled={this.state.loading} className="btn btn-lg btn-primary" onClick={() => this.setTabs(this.state.action + 1)}>
                                                        {this.state.loading ? 'Please wait' : 'Continue'}

                                                    </button>
                                                )
                                            }



                                        </div>

                                        {
                                            this.state.action === 5 && (

                                                <Link to="/inventories" type="button" className="btn btn-lg btn-primary">

                                                    Show Inventory
                                                </Link>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Onboarding;