import React from 'react'
import Navbar from "../layout/Navbar"
import { Link } from 'react-router-dom'

function Synchistory() {
	return (
		<>
			<Navbar />
			<div className="post d-flex flex-column-fluid" id="kt_post">
				<div id="kt_content_container" className="container-xxl">
					<div className="card-body pt-6">
						<ul className="nav nav-pills nav-pills-custom mb-3" role="tablist">
							<li className="nav-item mb-3 me-3 me-lg-6" role="presentation">
								<Link className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden pb-2" id="kt_stats_widget_16_tab_link_2" data-bs-toggle="pill" to="/Product" aria-selected="false" role="tab">
									<div className="nav-icon mb-3">
										<i className="fonticon-bank fs-1 p-0"></i>
									</div>
									<span className="nav-text text-gray-800 fw-bold fs-6 lh-1 pb20">Products</span>
									<span ></span>
								</Link>
							</li>
							<li className="nav-item mb-3 me-3 me-lg-6" role="presentation">
								<Link className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden pb-2" id="kt_stats_widget_16_tab_link_2" data-bs-toggle="pill" to="/collection" aria-selected="false" role="tab">
									<div className="nav-icon mb-3">
										<i className="fonticon-bank fs-1 p-0"></i>
									</div>
									<span className="nav-text text-gray-800 fw-bold fs-6 lh-1 pb20">Collections</span>
									<span ></span>
								</Link>
							</li>
							<li className="nav-item mb-3 me-3 me-lg-6" role="presentation">
								<Link className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden pb-2 active" id="kt_stats_widget_16_tab_link_1" data-bs-toggle="pill" to="/collection" aria-selected="true" role="tab">
									<div className="nav-icon mb-3">
										<i className="fonticon-drive fs-1 p-0"></i>
									</div>
									<span className="nav-text text-gray-800 fw-bold fs-6 lh-1 pb20">Synch History</span>
									<span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
								</Link>
							</li>
						</ul>
						<div className="tab-content">
							<div className="tab-pane fade active show" id="kt_stats_widget_16_tab_1" role="tabpanel" aria-labelledby="#kt_stats_widget_16_tab_link_1">
								<div className="card mb-5 mb-xl-10">
									<div className="card-header border-bottom cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
										<div className="card-title m-0">
											<h3 className="fw-bolder m-0">Synch History</h3>
										</div>
									</div>

									<div className="card-bodyy pt-6">
										<div className="card mb-5 mb-xl-10">

											<div id="kt_account_settings_profile_details" className="collapse show">

												<div className="table-responsive table-responsive2">
													<table className="table table-row-bordered align-middle gy-4 gs-9">
														<thead className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bolder bg-white bg-opacity-100">
															<tr>
																<td className="">Sync date</td>
																<td className="">Products processed:</td>
																<td className="">Collections processed</td>
																<td className="">Action state</td>
															</tr>
														</thead>
														<tbody className="fw-bold text-gray-600 connect-store-data">
															<tr>
																<td>Product Inserted</td>
																<td>x products</td>
																<td className="fw-bold">y collections </td>
																<td><div className="badge badge-light-success" style={{ width: 100 }}>Success</div></td>

															</tr>


															<tr>
																<td>Product Created</td>
																<td> x products</td>
																<td className="fw-bold">y collections </td>
																<td><div className="badge badge-light-danger" style={{ width: 100 }}>Fail</div></td>
															</tr>


														</tbody>
													</table>
												</div>
											</div>
										</div>

									</div>
								</div>


							</div>
						</div>

					</div>
				</div>
			</div>

		</>
	)
}

export default Synchistory